import React from "react";

import Layout from "../../components/layout";
import TermsLayout from "../../components/layout/terms-layout";
import ProfessionalServicesAgreement from "../../components/professional-services-agreement";
import SEO from "../../components/seo";

const ProfessionalServicePage = () => (
  <Layout isWhiteHeader paddingTop>
    <SEO
      title="Professional Services Agreement"
      keywords={[
        `web hosting`,
        `domain names`,
        `web site`,
        `search engine optimization`,
        `hosting`,
        `servers`,
      ]}
      pageType={"company_info"}
      flowType={"none"}
    />
    <TermsLayout>
      <ProfessionalServicesAgreement />
    </TermsLayout>
  </Layout>
);

export default ProfessionalServicePage;
