import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { Link } from 'gatsby'

import Typography from '@eig-builder/core-ui/Typography'

import { withModifiers } from '../../helpers/withModifiers'

const counterContainer = css`
  ${(props) =>
    props.counterSection &&
    `
      counter-reset: ${props.counterSection};
    `}
`

const counter = css`
  ${({ counter: pCounter, counterType = 'alphabetic' }) =>
    pCounter &&
    `
      &::before {
        counter-increment: ${pCounter};
        content: counter(${pCounter}, ${counterType}) ". ";
      }
    `}
`

const ProfessionalServices = styled.section.attrs((props) => ({
  className: withModifiers(props)
}))`
  ${space}
  ${counterContainer}

  &._borderBottom {
    padding-top: 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${({ theme }) => theme.palette.black.lighter};
  }
`

ProfessionalServices.subSection = styled.div`
  ${space}
  ${counterContainer}
`

ProfessionalServices.Header = styled(Typography).attrs(() => ({
  variant: 'h1'
}))({}, space)

ProfessionalServices.SubHeader = styled(Typography).attrs(() => ({
  variant: 'h2'
}))`
  ${counter}
  ${space}
`

ProfessionalServices.Text = styled(Typography).attrs(({ variant }) => ({
  variant: variant || 'body1'
}))({}, counter, space)

ProfessionalServices.basicText = styled.span.attrs((props) => ({
  className: withModifiers(props)
}))`
  ${space}
  &._bold {
    font-weight: bold;
    line-height: 1.42857;
  }
`

ProfessionalServices.uList = styled.ul.attrs(
  ({ listType = 'upper-alpha' }) => ({ listType })
)`
  list-style: ${({ listType }) => listType}};
`

ProfessionalServices.listItem = styled.li`
  padding: 8px 0;
`

ProfessionalServices.Link = styled(Link)`
  margin: 0 4px;
`

ProfessionalServices.pMargin = styled.p`
  margin: 8px 0;
`

export default ProfessionalServices
